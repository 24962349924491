import { render, staticRenderFns } from "./power-details.vue?vue&type=template&id=bec91800&scoped=true&"
import script from "./power-details.vue?vue&type=script&lang=js&"
export * from "./power-details.vue?vue&type=script&lang=js&"
import style0 from "./power-details.vue?vue&type=style&index=0&id=bec91800&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bec91800",
  null
  
)

export default component.exports